import React from 'react'
import {useStaticQuery,graphql} from 'gatsby'

import Layout from "../../components/layout"
import { createGlobalStyle } from "styled-components"
import { Helmet } from "react-helmet"
import SEO from "../../components/seo"
import Image from "../../components/image"

import featuredImage from "./ogpImage_complete-box.png"
import cbox from "../../components/css/ssd-cbox.module.css"


const GlobalStyle = createGlobalStyle`
  body { background-color: #f5f5f5 !important; font-family:sans-serif; color: black;}
  .icon {background-position: 0 0 !important;}
  footer {color: #454545 !important;}
  footer a {color: #000;}
`

const CompleteBox = () => {
  
  const query = useStaticQuery(graphql`
    query {
      cbox: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(complete-box.md)/"}}) {
        edges {
          node {
            fileAbsolutePath
            frontmatter {
              page
              title
              description
              order
              pathname
              thumbsImg {
                relativePath
              }
            }
            html
          }
        }
      }
  }
  `)

  const pageInfo = query.cbox.edges[0].node;

  const siteTitle = pageInfo.frontmatter.title;

  return (
    <Layout>
        <Helmet></Helmet>
        <SEO title={siteTitle}
            ogpImage={featuredImage} 
            description='佐野元春40周年記念プロジェクト'/>

        <React.Fragment>
            <GlobalStyle />
        </React.Fragment>

        <div id="complete-box" className={``}>

        <section className={`${cbox.hero} hero`}>
          <div className={`${cbox.heroFrame} hero-frame`}>

          <div className={`${cbox.detailVisualFrame} detail-visual-frame`}>
            <div className={`${cbox.detailVisualCopy} detail-visual-copy`}>
              <h1 className={`product-title aspect narrow`}>佐野元春 <br />ザ・コンプリート・アルバム・コレクション 1980-2004</h1>
              <hr />
            </div>
            <figure className={`${cbox.detailVisual}`}>
              <Image filename={"complete-box-detail01.jpg"} alt="THE COMPLETE ALBUM COLLECTION 1980-2004" />
            </figure>
          </div>
          <div className={`${cbox.detailVisualSeg2Frame} detail-visual-seg2-frame`}>
          <figure className={`${cbox.detailVisualSeg2}`}>
            <Image filename={"complete-box-detail02.jpg"} alt="THE COMPLETE ALBUM COLLECTION 1980-2004" />
          </figure>
          <figure className={`${cbox.detailVisualSeg2}`}>
            <Image filename={"complete-box-detail03.jpg"} alt="THE COMPLETE ALBUM COLLECTION 1980-2004" />
          </figure>
          </div>

          </div>
        </section>

        <section className={`${cbox.info} info`}>
            <h2 className={`${cbox.releaseInfo} release-info`}>2021年6月16日発売</h2>
            <a href="https://www.moto.co.jp/Save-it-for-a-Sunny-Day/complete-box/comment/" className={`${cbox.commentButton} comment-button`} title="リスナーからのコメント募集"><span className={`${cbox.comment} comment`}>リスナーからの感想募集中</span></a>
            <div className={`${cbox.productDetail} product-detail`}>
                <div className={`${cbox.storesFrame} store-frame`}>
                    <span className={`${cbox.label} label`}>オンラインストアで購入</span>
                    <ul className={`${cbox.stores} stores`}>							
                        <li><a href="https://www.sonymusicshop.jp/m/item/itemShw.php?site=S&cd=MHCL000002871&cid=oto-MHCL000002871" target="_blank" rel="noreferrer" title="ソニーミュージックショップ" class="sonymusic" onclick="recordOutboundLink(this, 'OutboundLink-MS-COMPLETE-ALBUM-BOX-buy', 'sonymusic');return false;">ソニーミュージックショップ</a></li>
                        <li><a href="https://amzn.to/38IEpGP" target="_blank" rel="noreferrer" title="アマゾン" class="amazon" onclick="recordOutboundLink(this, 'OutboundLink-MS-COMPLETE-ALBUM-BOX-buy', 'amazon');return false;">アマゾン</a></li>
                        <li><a href="https://tower.jp/item/5143839" target="_blank" rel="noreferrer" title="タワーレコード" class="tower" onclick="recordOutboundLink(this, 'OutboundLink-MS-COMPLETE-ALBUM-BOX-buy', 'tower');return false;">タワーレコード</a></li>
                        <li><a href="https://www.hmv.co.jp/product/detail/11500241" rel="noreferrer" target="_blank" title="HMV" class="hmv" onclick="recordOutboundLink(this, 'OutboundLink-MS-COMPLETE-ALBUM-BOX-buy', 'hmv');return false;">HMV</a></li>
                        <li><a href="https://books.rakuten.co.jp/rb/16589274" target="_blank" rel="noreferrer" title="楽天BOOKS" class="rakuten" onclick="recordOutboundLink(this, 'OutboundLink-MS-COMPLETE-ALBUM-BOX-buy', 'rakuten');return false;">楽天BOOKS</a></li>
                    </ul>
                </div>
            </div> 
            <a className={`${cbox.otonano} otonano`} href="https://www.110107.com/s/oto/page/sano_collection?ima=1428" target="_blank" rel="noreferrer" >詳細情報は otonano by Sony Music Direct へ</a>
        </section>

        <section className={`${cbox.leadCopyFrame} lead-copy-frame aspect narrow`}>
            <p className={`${cbox.leadCopy} lead-copy`}>
                <div dangerouslySetInnerHTML={{ __html: pageInfo.html }} />
            </p>
        </section>

        </div>
    </Layout>
    )
}

export default CompleteBox