import React from 'react'
import {Link,graphql} from 'gatsby'
import Layout from "../../components/layout"
import Image from "../../components/image"
import { createGlobalStyle } from "styled-components"
import SEO from "../../components/seo"
import featuredImage from "./ogpImage_ssd-ffes.png"

import fes from "../../components/css/ssd-filmfes.module.css"
import fesTitle from  "./ffes-title.png"

const GlobalStyle = createGlobalStyle`
  body { background-color: #000 !important; font-family:sans-serif; color: white;}
  header {height: 44pt;}
  .ssd-frame {display:none;}
  .icon {background-position: 0 -30px !important;}
  #fes-07 > .infoFrame-lower > div > ul > li:nth-child(6) {display: block;font-size: 90%;font-weight: 100;}
  #fes-07 > .infoFrame-lower > div > ul > li:nth-child(6):before {content: "※";}
`

export const query = graphql`
{
  page: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(film-festival.md)/"}}) {
    edges {
      node {
        frontmatter {
          page
          title
          thumbsImg {
            relativePath
          }
          order
        }
        html
        fileAbsolutePath
      }
    }
  }

  festival: allMarkdownRemark(filter: {frontmatter: {page: {eq: "ffes"}}}, sort: {fields: frontmatter___date, order:  DESC}) {
    edges {
      node {
        fileAbsolutePath
        frontmatter {
          page
          vol
          ticket
          date(formatString: "")
          title
          thumbsImg {
            relativePath
          }
          buttonLabel
          url
        }
        html
      }
    }
  }
  
  product: allMarkdownRemark(filter: {frontmatter: {page: {eq: "merchandise"}}}, sort: {fields: frontmatter___order, order: ASC}) {
    edges {
      node {
        fileAbsolutePath
        frontmatter {
          page
          url
          date(formatString: "")
          title
          thumbsImg {
            relativePath
          }
          order
          productNo
          productType
          price
          releaseDate(formatString: "")
        }
        html
      }
    }
  }
}
`
const FilmFes = (props) => {
  const { page } = props.data;
  const { festival } = props.data;
  const { product } = props.data;

  var pageInfo = page.edges[0].node;
  
  var fesInfo = [];
  festival.edges.map(function(node) {
    return fesInfo.push(node)
  })

  var ProductInfo = [];
  product.edges.map(function(node) {
    return ProductInfo.push(node)
  })

  const siteTitle = "佐野元春40周年記念フィルムフェスティバル"

  return (
    <Layout>

      <SEO title={siteTitle}
          ogpImage={featuredImage} 
          description='佐野元春40周年記念プロジェクト'/>

      <React.Fragment>
        <GlobalStyle />
      </React.Fragment>

      <div id="film-festival" className={``}>
        <section className={`${fes.hero} hero-frame`}>
          <Link to="/Save-it-for-a-Sunny-Day/" className={`${fes.heroSticker} hero-sticker`}>
            <Image filename={"ssd_sticker-circle.png"} alt="Save it for a sunnyday" />
          </Link>
          <div className={`${fes.heroImageFrame} hero-image-frame`} >
            <figure className={`${fes.heroImage} hero-image`}>
              <Image filename={"ffes-hero.jpg"} alt={pageInfo.frontmatter.title}  />
            </figure>
          </div>
          <div className={`${fes.heroCopyFrame} hero-copy-frame`} >
              <p className={`${fes.period} period`}>2021年12月22日 第7回配信決定</p>
              <h1 className={`${fes.title} title`} dangerouslySetInnerHTML={{ __html: pageInfo.frontmatter.title }} style={{ backgroundImage: `url(${fesTitle})`}}/>
              <article className={`${fes.heroCopy} hero-copy`} dangerouslySetInnerHTML={{ __html: pageInfo.html }} />
            </div>
        </section>

        <section className={`${fes.fesFrame} fes-frame`}>
        {fesInfo.map((key, i) => {
              const date = key.node.frontmatter.date.slice(0,10)
              const title = key.node.frontmatter.title
              const thumbsImg = (key.node.frontmatter.thumbsImg) ? key.node.frontmatter.thumbsImg.relativePath : ""
              const isThumbsImg = (key.node.frontmatter.thumbsImg) ? true : false
              const description = key.node.html
              const ary = key.node.fileAbsolutePath.split('/')
              const md = ary.slice(-1)[0];
              const slug = md.replace( /.md/g , "" )

              const vol = key.node.frontmatter.vol
              const ticket = key.node.frontmatter.ticket
              const url = key.node.frontmatter.url
              const isURL = (url) ? true : false
              const buttonLabel = key.node.frontmatter.buttonLabel

              // 日付がマッチするプロダクトを抽出
              var relatedProducts = []
              ProductInfo.map((data,i) => {
                const releaseDate = data.node.frontmatter.releaseDate.slice(0,10)
                if (date === releaseDate) {
                  return relatedProducts.push(data);
                }
                return relatedProducts
              })
              var month , isProducts = false
              if (relatedProducts.length) {
                isProducts = true;
                month = new Date(date).getMonth() + 1
              }

              if (!isThumbsImg) {
                return (
                  <div className={`${fes.ScreeningSchedule} screening-schedule`}>
                    <h2 className={`${fes.scheduleHeading} aspect narrow`}>今後の上映スケジュール</h2>
                    <p className={`${fes.scheduleTitle} c-${slug} aspect narrow`}>#{vol} {title}</p>
                  </div>
                )
              } else {
                return (
                  <div id={`fes-${vol}`} className={`${fes.itemFrame} itemFrame`} >
                    <h2 className={`${fes.itemTitle} c-${slug} aspect narrow`} key={i}>{date}｜#{vol} {title}</h2>
                    <div className={`${fes.infoFrameUpper} infoFrame-upper`}>
                      <a href={url} className={`${fes.fesImageLink} fes-image-link`} target="_blank" rel="noreferrer">
                        <Image filename={thumbsImg} alt={title} className={`${fes.fesImage} fes-image`} />
                      </a>
                      <div className={`${fes.textFrame} textFrame`}>
                        <div className={`${fes.description} description`} dangerouslySetInnerHTML={{ __html: description }} />
                      </div>
                    </div>

                    <div className={`${fes.infoFrameLower} infoFrame-lower`}>

                    {isProducts && (
                      <Link to="/Save-it-for-a-Sunny-Day/merchandise/" className={`${fes.productFrame} productFrame`}>
                        <figure className={`${fes.merchandiseTitle} merchandise-title`}>
                          <Image filename={"Moto-BEAMS-title_white.png"} alt=""  />
                        </figure>
                        <p className={`${fes.merchandiseLineup} merchandise-lineup`}>{month}月の追加ラインナップ</p>
                        <ul className={`${fes.productItem} productItem`}>
                        {relatedProducts.map((key,i) => {
                          const pName = key.node.frontmatter.title

                          return(
                              <li className={`${fes.productTitle} product-title`} key={i} dangerouslySetInnerHTML={{ __html: pName }} />
                          )
                        })}
                        </ul>
                      </Link>
                    )}

                      <div className={`${fes.buyFrame} buy-frame`}>
                          <ul className={`${fes.ticketFrame} ticket-frame`}>
                          {ticket.map((key, i) => {
                            return(
                            <li className={`${fes.ticketLabel} ticket-label`} key={i}>{key}</li>
                            )
                          })}
                          </ul>
                          <figure className={`${fes.buttonFrame} button-frame`}>
                          {isURL && (
                              <a href={url} className={`${fes.button} button`} target="_blank" rel="noreferrer">
                                <span className={`${fes.buttonLabel} button-label`} dangerouslySetInnerHTML={{ __html: buttonLabel }} />
                              </a>
                          )}
                          {!isURL && (
                              <figure className={`${fes.button} button soon`}>
                                <span className={`${fes.buttonLabel} button-label`} dangerouslySetInnerHTML={{ __html: buttonLabel }} />
                              </figure>
                          )}
                          </figure>
                      </div>
                    </div>
                  </div>
                )
              }
            })}

        </section>
      </div>
    </Layout>
  )
}

export default FilmFes