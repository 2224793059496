import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import style from "../components/css/header.module.css"

import ssdIcon from "../images/globalMenu_ssd.png"
import AppleIcon from "../images/serviceMenu_apple.svg"
import FBIcon from "../images/serviceMenu_facebook.svg"
import InstaIcon from "../images/serviceMenu_instagram.svg"
import SpotifyIcon from "../images/serviceMenu_spotify.svg"
import TwitterIcon from "../images/serviceMenu_twitter.svg"
import YouTubeIcon from "../images/serviceMenu_youtube.svg"

const Header = ({ siteTitle }) => (
  <header
    style={{
      display: `flex`
    }}
  >
    <div
      className={`${style.ssdFrame} icon ssd-frame`}
      style={{
        margin: `0`,
        padding: `0 1.0875rem`,
        width: `90pt`,
        height: `24pt`
      }}
    >
      <h1 style={{
        margin: 0,
        width: `100%`,
        height: `100%`
      }}>
        <Link
          to="/"
          className={`${style.ssdIcon} icon ssdIcon`}
          style={{
            display: `block`,
            width: `100%`,
            height: `100%`,
            color: `white`,
            textDecoration: `none`,
            backgroundImage: `url(${ssdIcon})`,
          }}
        >

        </Link>
      </h1>
    </div>
    <div className={`${style.secondaryMenu} secondary-menu-frame`} >
      <ul className={`${style.snsIconFrame} snsIcon-frame`} >
        <li className={`${style.snsIcon} snsIcon`} ><a href="https://www.facebook.com/motoharusano" target="_blank" rel="noreferrer" title="Facebook : 佐野元春 公式ページ" className={`${style.link}`} ><figure className={`${style.icon} icon facebookIcon`} style={{ backgroundImage: `url(${FBIcon})`}}>佐野元春オフィシャルFacebookページ</figure></a></li>
        <li className={`${style.snsIcon} snsIcon`} ><a href="https://twitter.com/MotoWebServer" rel="noreferrer" target="_blank" title="Twitter : @MotoWebServer" className={`${style.link}`} ><figure className={`${style.icon} icon twitterIcon`} style={{ backgroundImage: `url(${TwitterIcon})`}}>Twitter: @MotoWebServer</figure></a></li>
        <li className={`${style.snsIcon} snsIcon`} ><a href="https://www.instagram.com/hellodaisyheads/" rel="noreferrer" target="_blank" title="Instagram : HelloDaisyheads" className={`${style.link}`} ><figure className={`${style.icon} icon instagramIcon`} style={{ backgroundImage: `url(${InstaIcon})`}}>Instagram: HelloDaisyHeads</figure></a></li>
        <li className={`${style.snsIcon} snsIcon`} ><a href="https://www.youtube.com/user/DaisyMusic/featured" rel="noreferrer" target="_blank" title="YouTube : DaisyMusicチャンネル" className={`${style.link}`} ><figure className={`${style.icon} icon youtubeIcon`} style={{ backgroundImage: `url(${YouTubeIcon})`}}>DaisyMusic on YouTube</figure></a></li>
        <li className={`${style.snsIcon} snsIcon`} ><a href="https://itunes.apple.com/jp/artist/id78742728" rel="noreferrer" target="_blank" title="Apple Music : 佐野元春" className={`${style.link}`} ><figure className={`${style.icon} icon appleIcon`} style={{ backgroundImage: `url(${AppleIcon})`}}>Apple Music:佐野元春</figure></a></li>
        <li className={`${style.snsIcon} snsIcon`} ><a href="https://open.spotify.com/artist/286Db6EQmfgB3SJYulhGqb?si=CkwqR43cSIGuGc5ld0M4nA" rel="noreferrer" target="_blank" title="Spotify : 佐野元春" className={`${style.link}`} ><figure className={`${style.icon} icon spotifyIcon`} style={{ backgroundImage: `url(${SpotifyIcon})`}}>Spotify:佐野元春</figure></a></li>
      </ul>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
