/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const $ = require("jquery")

export const onRouteUpdate = () => {

    $(function(){
        $('#now-and-then-navi .decadeSelector .menu').removeClass('active');
        $('#now-and-then-navi .decade-contents').removeClass('active');
        if ($('#now-and-then-navi').hasClass('the80s')) {
            $('#now-and-then-navi .the80s').addClass('active');
        } else if ($('#now-and-then-navi').hasClass('the90s')) {
            $('#now-and-then-navi .the90s').addClass('active');
        } else if ($('#now-and-then-navi').hasClass('the00s')) {
            $('#now-and-then-navi .the00s').addClass('active');
        } else if ($('#now-and-then-navi').hasClass('the10s')) {
            $('#now-and-then-navi .the10s').addClass('active');
        } else {
            $('#now-and-then-navi .the80s').addClass('active');
        }

        $('#now-and-then-navi .decadeSelector .menu').click(function () {
            console.log('click');
            $('#now-and-then-navi .decadeSelector .menu').removeClass('active');
            $('#now-and-then-navi .decade-contents').removeClass('active');
            if ($(this).hasClass('the80s')) {
                $('#now-and-then-navi .the80s').addClass('active');
            } else if ($(this).hasClass('the90s')) {
                $('#now-and-then-navi .the90s').addClass('active');
            } else if ($(this).hasClass('the00s')) {
                $('#now-and-then-navi .the00s').addClass('active');
            } else if ($(this).hasClass('the10s')) {
                $('#now-and-then-navi .the10s').addClass('active');
            }
        });
     });


     if (window.location.pathname.match(/contribution/)) { // 評論ページのみ適用 
        // URLからカテゴリーパラメーターを取得
        var cat = getUrlParam().cat
        if (typeof cat === 'undefined') {
            cat = 'ALL';
        } else {
            cat = decodeURI(cat); // パラメーターが日本語の場合URLデコード
            let menuElem = document.getElementById('menu');
            if (menuElem) {
                var menuPos = menuElem.getBoundingClientRect();
                window.scrollTo( 0, menuPos.top);
            }
        }
        selectArticleByCategory(cat);
        
        // メニューが選択された際の処理
        $('.menu').click(function() {
            cat = $(this).attr('data-cat');
            window.history.replaceState('','','?cat='+cat); // ブラウザーのアドレスバーを書き換え
            selectArticleByCategory(cat);
        });

        // 記事一覧から記事が選択された際の処理
        $('.article-link').click(function() {
            if (cat !== 'ALL') {
                // a hrefではなくlocation.hrefでパラメーター指定
                window.location.href = $(this).attr('href') + '?cat=' + cat;
            }
        });

        // 記事ページのナビゲーション表示操作
        $('.article-pageNav').each(function(idx, elem) {
            if ($(elem).attr('data-cat') === cat) {
                $(elem).css({'display':'block'});
            } else {
                $(elem).css({'display':'none'});
            }
        });
     }

     function selectArticleByCategory(cat) {
        $('.menu').removeClass('selected');
        $('.menu').each(function(idx, elem) {
            if ($(elem).attr('data-cat') === cat) {
                $(elem).addClass('selected');
            }
        });

        $('.article-item').css({'display':'none'});

        if (cat === 'ALL') {
            $('.article-item').css({'display':'block'});
        } else {
            $('.article-item').each(function(idx, elem) {
                if ($(elem).attr('data-cat').match(new RegExp(cat))) {
                    $(elem).css({'display':'block'});
                }
            });
        }
     }

     function getUrlParam() {
        var q = window.location.search.split("?"),
            a,
            i,
            s,
            o;
        if (q.length > 1) {
            o = {};
            q = q[1];
            a = q.split("&");
            for (i = 0; i < a.length; i++) {
                s = a[i].split("=");
                s[0] = s[0].replace("%5B%5D", "");
                s[0] = s[0].replace("[]", "");
                if (!o[s[0]]) {
                    o[s[0]] = [s[1]];
                } else {
                    o[s[0]].push(s[1]);
                }
            }
        }
        if (typeof o === 'undefined') {
            o = "";
        }
        return o;
    }
}