import React from 'react'
import {Link,graphql} from 'gatsby'
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import ssd from "../components/css/ssd.module.css"
import { createGlobalStyle } from "styled-components"
import titleLogo from "../images/ms40th_project_green.svg"
import featuredImage from "../ssd/ogpImage_ssd.png"

const GlobalStyle = createGlobalStyle`
  body { background-color: #000 !important; font-family:sans-serif;}
  header {height:auto;}
  .ssd-frame {display:none;}
  .icon {background-position: 0 -30px !important;}
`
export const query = graphql`
{
  item: allMarkdownRemark(filter: {frontmatter: {page: {eq: "ssd"}, thumbsImg: {absolutePath: {ne: "null"}}}}, sort: {fields: frontmatter___order, order: ASC}) {
    edges {
      node {
        fileAbsolutePath
        frontmatter {
          page
          title
          description
          order
          pathname
          thumbsImg {
            relativePath
          }
        }
        html
      }
    }
  }
}
`
const Ssd = (props) => {
  const { item } = props.data;

  var contents = [];
  item.edges.map(function(node) {
    return contents.push(node);
  })

  let hero = contents[0].node;
  contents.splice(0, 1);

  const heroImagePC = "ssd_banner.png"
  const heroImageSP = "ssd_banner_sp.png"

  return (
    <Layout>

      <SEO title={hero.frontmatter.title}
          ogpImage={featuredImage} 
          description='佐野元春40周年記念プロジェクト'/>

      <React.Fragment>
        <GlobalStyle />
      </React.Fragment>

      <div id="ssd-top" className={``}>
        <h1 className={`${ssd.title} title`} style={{ backgroundImage: `url(${titleLogo})`}}>佐野元春40周年記念プロジェクト</h1>
        <section className={`${ssd.hero} hero-frame`}>
          <div className={`${ssd.heroImageFramePC} hero-image-frame`} >
            <Image filename={heroImagePC} alt={hero.frontmatter.title} className={`${ssd.heroImage} hero-image`} />
          </div>
          <div className={`${ssd.heroImageFrameSP} hero-image-frame`} >
            <Image filename={heroImageSP} alt={hero.frontmatter.title} className={`${ssd.heroImage} hero-image`} />
          </div>
          <div className={`${ssd.heroCopyFrame} hero-copy-frame`} >
            <article className={`${ssd.heroCopy} hero-copy`} dangerouslySetInnerHTML={{ __html: hero.html }} />
          </div>
        </section>

        <section className={`${ssd.badgeFrame} badge-frame`}>
        {contents.map((key, i) => {
              let title = key.node.frontmatter.title
              let thumbsImg = key.node.frontmatter.thumbsImg.relativePath
              let ary = key.node.fileAbsolutePath.split('/')
              let md = ary.slice(-1)[0];
              let slug = md.replace( /.md/g , "" )
              let path = key.node.frontmatter.pathname
              let description = key.node.frontmatter.description

              var outerLink = false;
              if (path !== null) {
                outerLink = (path.match(/http/)) ? true : false
              }
              let withPath = (path !== null) ? true : false

              var withDesc = false;
              if (md === 'SSD-Playlist.md') {
                withDesc = true;
              }

              return (
                <div className={`${ssd.badge} ${slug}`} key={i}>
                {withPath && (
                  outerLink ? (
                    <a href={path} target="_blank" rel="noreferrer" className={`${ssd.badgeAnchor} badge-anchor`} >
                      <Image filename={thumbsImg} alt={title} className={`${ssd.badgeImage} badge-image`} />
                    </a>
                  ) : (
                    <Link to={path} className={`${ssd.badgeAnchor} badge-anchor`} >
                      <Image filename={thumbsImg} alt={title} className={`${ssd.badgeImage} badge-image`} />
                    </Link>
                  )
                )}
                {!withPath && (
                  <Image filename={thumbsImg} alt={title} className={`${ssd.badgeImage} badge-image`} />
                )}
                {withDesc && (
                  <span className={`${ssd.description} description`} dangerouslySetInnerHTML={{ __html: description }} />
                )}
              </div>
              )
            })}
        </section>
      </div>
    </Layout>
  )
}

export default Ssd