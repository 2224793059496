import React from 'react'
import {useStaticQuery,graphql, Link} from 'gatsby'

import Layout from "../../components/layout"
import { createGlobalStyle } from "styled-components"
import SEO from "../../components/seo"

import featuredImage from "./ogpImage_ssd-yah40th.png"
import yah40th from "../../components/css/ssd-yah40th.module.css"

const GlobalStyle = createGlobalStyle`
  body { background-color: #303135 !important; font-family:sans-serif; color: #FAFEFF;}
  header {background-color: #729001 !important;}
  .icon {background-position: 0 -30px !important;}
  footer {background-color: #212121 !important; color: gray !important;}
  footer a {color: #fff;}
`

const Yah40th = () => {

    const md = useStaticQuery(graphql`
        query {
            allMarkdownRemark {
                edges {
                    node {
                        fileAbsolutePath
                        frontmatter {
                            page
                            title
                            description
                            heroText
                            mainImg {
                                relativePath
                            }
                            commentURL
                            videoURL
                            relatedURL
                            theBand
                        }
                        html
                    }
                }
            }
        }
    `)

    var pageInfo;
    md.allMarkdownRemark.edges.map(function(node) {
        const path = node.node.fileAbsolutePath
        if (path.indexOf('yah40th-overview.md') !== -1) {
            pageInfo = node.node;
        }
        return pageInfo
    })

    // 必須項目
    let page = pageInfo.frontmatter.page
    let title = pageInfo.frontmatter.title
    let desc = pageInfo.frontmatter.description

  return (
    <Layout>
        <SEO title={title}
            ogpImage={featuredImage} 
            description={desc} />

        <React.Fragment>
            <GlobalStyle />
        </React.Fragment>

        <div id="yah40th" className={`${yah40th.contents} ${page}`}>

            <section className={`${yah40th.overview} overview`}>
                <div dangerouslySetInnerHTML={{ __html: pageInfo.html }} />
            </section>

            <Link to="/Save-it-for-a-Sunny-Day/yah40th/" className={`${yah40th.buttonLink} button-link`}>ヤァ！40年目の武道館＆城ホール 公演情報</Link>

        </div>
    </Layout>
    )
}

export default Yah40th