import React from 'react'
import {graphql} from 'gatsby'

import Layout from "../../components/layout"
import { createGlobalStyle } from "styled-components"
import { Helmet } from "react-helmet"
import SEO from "../../components/seo"

import AppleMusicIcon from "../../images/serviceLogo_appleMusic_white.svg"
import featuredImage from "./ogpImage_ssd-yah40th.png"
import yah40th from "../../components/css/ssd-yah40th.module.css"

import * as common from "../../components/common"

const GlobalStyle = createGlobalStyle`
  body { background-color: #303135 !important; font-family:sans-serif; color: #FAFEFF;}
  header {background-color: #729001 !important;}
  .icon {background-position: 0 -30px !important;}
  footer {background-color: #212121 !important; color: gray !important;}
  footer a {color: #fff;}
`
export const query = graphql`
query {
    songDb: allOriginalAlbum {
        edges {
            node {
                id
                trackList {
                    songID
                    title
                }
                releaseDate
                name {
                    title
                }
                itunes
            }
        }
    }
}
`

const Yah40th = (props) => {
    const { songDb } = props.data;
    const live = props.pageContext.live
    const setlist = props.pageContext.live.setlist

    const slug = live.slug
    const title = live.title
    const band = live.band
    const venue = live.venue
    const date = common.formatDate(new Date(live.date), 'yyyy-M-d')
    const isView = live.isView

    setlist.sort(function(a,b){
        if(a.listNo < b.listNo) return -1;
        if(a.listNo > b.listNo) return 1;
        return 0;
    });

    let dateAry = date.split('-')

  return (
    <Layout>
        <Helmet></Helmet>
        <SEO title={`${title}${venue} セットリスト`}
            ogpImage={featuredImage} 
            description='佐野元春40周年記念プロジェクト'/>

        <React.Fragment>
            <GlobalStyle />
        </React.Fragment>

        <div id="yah40th" className={`${yah40th.contents} ${slug}`}>
            <section className={`${yah40th.setlistData} setlistData`}>
                <figure className={`${yah40th.setlistCalendar} date`} date={`${date}`}>
                    <div>
                        <span>{dateAry[0]}</span>
                        <span>{dateAry[1]}</span>
                    </div>
                    <p>{dateAry[2]}</p>
                </figure>
                <div className={`${yah40th.setlistTitle} setlistTitle`}>
                    <h1>{title} <span>{band}</span></h1>
                    <p>{venue}</p>
                </div>
            </section>
        
            {isView && (
            <section className={`${yah40th.setlistFrame} setlistFrame`}>
                {setlist.map((data, i) => {
                    let isStatus = (data.status !== '') ? true : false
                    let isNote = (data.note !== '') ? true : false
                    var catalog = []
                    songDb.edges.map((album) => {
                        const result = album.node.trackList.find((v) => v.songID === data.songId);
                        if (result) {
                            const trackInfo = {
                                album: album.node.name.title,
                                releaseDate: album.node.releaseDate,
                                title: result.title,
                                itunes: album.node.itunes
                            }
                            catalog.push(trackInfo)
                        }
                        return catalog
                    })
                    let songTitle = (data.songTitle) ? data.songTitle : catalog[0].title
                    var year = '', album, itunes
                    if (catalog.length > 0) {
                        if (catalog[0].releaseDate) {
                            year = catalog[0].releaseDate
                            year = year.replace(' ','T')
                            year = common.formatDate(new Date(year), 'yyyy')
                        }
                        album = (catalog[0].album) ? catalog[0].album : ''
                        itunes = (catalog[0].itunes) ? catalog[0].itunes : ''
                    }
                    let isAppleMusic = (itunes !== '') ? true : false
                    return (
                        <div className={`${yah40th.setlistItem} setlistItem`}>
                            {isStatus && (
                                <aside>{data.status}</aside>
                            )}
                            <div className={`${yah40th.setlistItemFrame} setlistItemFrame`}>
                                <i>{data.listNo}</i>
                                <div>
                                    <p className={`${yah40th.setlistItemTitle} setlistItemTitle`}>{songTitle} <span>{data.songSubTitle}</span></p>
                                    <div className={`${yah40th.setlistItemNote} setlistItemNote`}>
                                        {isNote && (
                                            <aside>{data.note}</aside>
                                        )}
                                        <span>{album}</span> <span>{year}</span>
                                        {isAppleMusic && (
                                            <a href={`https://music.apple.com/jp/album/${itunes}`}
                                            className={`${yah40th.setlistAppleMusic} setlistAppleMusic`}
                                            style={{
                                                backgroundImage: `url(${AppleMusicIcon})`,
                                              }}
                                            >Apple Music</a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </section>
            )}
            {!isView && (
                <section className={`${yah40th.setlistHidden} setlistHidden`}>
                    <p>セットリストの公開まで、しばらくお待ちください。</p>
                </section>
            )}
        </div>
    </Layout>
    )
}

export default Yah40th