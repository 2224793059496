import React from 'react'
import {Link} from 'gatsby'

import Layout from "../../components/layout"
import Image from "../../components/image"
import { createGlobalStyle } from "styled-components"
import { Helmet } from "react-helmet"
import SEO from "../../components/seo"

import separator from "./40th-MRS-separator.png"
import featuredImage from "./ogpImage_ssd-mrs.png"

import mrs from "../../components/css/ssd-mrs.module.css"

const GlobalStyle = createGlobalStyle`
  body { background-color: #0f161c !important; font-family:sans-serif; color: white;}
  .ssd-frame {display:none;}
  .icon {background-position: 0 -30px !important;}
  footer {color: #fff !important;}
  footer a {color: #fff;}
`

const MRS = () => {

  const siteTitle = "FM特番 元春レイディオショー 2020年7月-9月"

  return (
    <Layout>
        <Helmet>
            {/* <script src="https://code.jquery.com/jquery-3.4.1.min.js" integrity="sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo=" crossOrigin="anonymous" />
            <script src={withPrefix('ssd-mrs-script.js')} type="text/javascript" /> */}
        </Helmet>
        <SEO title={siteTitle}
            ogpImage={featuredImage} 
            description='佐野元春40周年記念プロジェクト'/>

        <React.Fragment>
            <GlobalStyle />
        </React.Fragment>

        <div id="MRS40th" className={``}>

        <section className={`${mrs.hero} hero-frame`}>
            <Image filename={"40th-MRS-title.png"} className={`${mrs.heroImage} hero-image`} alt="FM特番 元春レイディオショー 2020年7月-9月 毎週金曜日 夜9時" />

            <Link to="/Save-it-for-a-Sunny-Day/" className={`${mrs.heroSticker} hero-sticker`}>
                <Image filename={"ssd_sticker-circle.png"} alt="Save it for a sunnyday" />
            </Link>

            <h1 className={`${mrs.heroCopy} hero-copy aspect narrow`}>
                佐野元春40周年を記念して『元春レイディオ・ショー』が<br />
                FM COCOLOで期間限定復活<br />
                2020年7月 - 9月 全13回、毎週金曜日 21:00 - 22:00オンエア
            </h1>
        </section>

        <section className={`${mrs.leadCopyFrame} lead-copy-frame aspect narrow`}>
            <p className={`${mrs.leadCopy} lead-copy`}>
                80年代からステーションを変えて続く伝説の音楽番組「元春レイディオ・ショー」。その特徴はなんといっても、佐野元春自身による構成・選曲と、トークから音楽につながる洗練されたスムースなフローにある。すべての音楽リスナー必聴の番組だ。
            </p>
            <p className={`${mrs.leadCopy} lead-copy`}>
                大阪近郊の方はFM COCOLO（76.5MHz）で聴取できるほか、Radiko（ラジコ）プレミアムに登録するとタイムフリーで全国から楽しめます。
            </p>
        </section>

        <hr className={`${mrs.separator} separator`} style={{ backgroundImage: `url(${separator})`}} />

        <section className={`${mrs.programInfoFrame} program-info-frame`}>
            <span className={`${mrs.label} label`}>番組ブログ</span>
            <a href="https://cocolo.jp/service/homepage/index/5210" target="_blank" rel="noreferrer" className={`${mrs.url} url blog`}>https://cocolo.jp/service/homepage/index/5210</a>

            <span className={`${mrs.label} label`}>番組へコメントを送る</span>
            <a href="https://cocolo.jp/service/Request/index/member/5210" target="_blank" rel="noreferrer" className={`${mrs.url} url comment`}>https://cocolo.jp/service/Request/index/member/5210</a>
        </section>

        </div>
    </Layout>
    )
}

export default MRS