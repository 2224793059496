import React from 'react'
import {graphql,Link} from 'gatsby'

import Layout from "../../components/layout"
import Image from "../../components/image"
import { createGlobalStyle } from "styled-components"
import SEO from "../../components/seo"

import CboxInfo from "../../components/complete-box-info"
import featuredImage from "./ogpImage_ssd-yah40th.png"
import yah40th from "../../components/css/ssd-yah40th.module.css"

import * as common from "../../components/common"

const GlobalStyle = createGlobalStyle`
  body { background-color: #303135 !important; font-family:sans-serif; color: #FAFEFF;}
  header {background-color: #729001 !important;}
  .icon {background-position: 0 -30px !important;}
  footer {background-color: #212121 !important; color: gray !important;}
  footer a {color: #fff;}
`
export const query = graphql`
query {
    page: allMarkdownRemark (filter: {fileAbsolutePath: {regex: "/(yah40thInfo.md)/"}}) {
        edges {
            node {
                fileAbsolutePath
                frontmatter {
                    page
                    title
                    description
                    heroText
                    mainImg {
                        relativePath
                    }
                    commentURL
                    videoURL
                    relatedURL
                    theBand
                    notice
                }
                html
            }
        }
    }
    tour: allMarkdownRemark(filter: {frontmatter: {page: {eq: "yah40th"}}}, sort: {fields: frontmatter___order, order: ASC}) {
       edges {
            node {
                fileAbsolutePath
                frontmatter {
                    date(formatString: "")
                    doorOpen
                    eventStatus
                    organizer
                    performer
                    price
                    priceCurrency
                    slug
                    start
                    title
                    validFrom(formatString: "")
                    venue
                    week
                    organizerUrl
                    previousStartDate
                    notice
                }
                html
            }
        }
    }
}
`

const Yah40th = (props) => {
    const { page } = props.data;
    const { tour } = props.data;

    var pageInfo = page.edges[0].node;

    var tourInfo = [];
    tour.edges.map(function(node) {
        return tourInfo.push(node);
    })

    // 必須項目
    let title = pageInfo.frontmatter.title
    let desc = pageInfo.frontmatter.description
    let heroText = pageInfo.frontmatter.heroText
    let mainImg = pageInfo.frontmatter.mainImg.relativePath

    // 任意項目
    let commentURL = (pageInfo.frontmatter.commentURL) ? pageInfo.frontmatter.commentURL : ""
    let isComment = (pageInfo.frontmatter.commentURL) ? true : false
    let videoURL = (pageInfo.frontmatter.videoURL) ? pageInfo.frontmatter.videoURL : ""
    let isVideoURL = (pageInfo.frontmatter.videoURL) ? true : false
    let relatedURL = (pageInfo.frontmatter.relatedURL) ? pageInfo.frontmatter.relatedURL : ""
    let isRelatedURL = (pageInfo.frontmatter.relatedURL) ? true : false
    let notice = (pageInfo.frontmatter.notice) ? pageInfo.frontmatter.notice : ""
    let isNotice = (pageInfo.frontmatter.notice) ? true : false
     
    // バンド
    var bandName;
    var bandMember = [];
    pageInfo.frontmatter.theBand.map((text, i) => {
        if (i === 0) {
            bandName = text;
            return bandName;
        } else {
            return bandMember.push(text);
        }
    })

  return (
    <Layout>
        <SEO title={title}
            ogpImage={featuredImage} 
            description={desc} />

        <React.Fragment>
            <GlobalStyle />
        </React.Fragment>

        <div id="yah40th" className={`${yah40th.contents}`}>

            <section className={`${yah40th.hero} hero`}>
                <h1 className={`${yah40th.tourTitle} tour-title aspect narrow`} dangerouslySetInnerHTML={{ __html: heroText }} />
                <figure className={`${yah40th.heroFrame} hero-frame`}>
                    <div className={`${yah40th.heroImageFrame} hero-image-frame`}>
                        <i className={`${yah40th.doNotCopy} doNotCopy`}></i>
                        <picture className={`${yah40th.heroImage} hero-image`}>
                            <Image filename={mainImg} alt={`${title}`} />
                        </picture>
                    </div>
                    <div className={`${yah40th.heroButtonFrame} hero-button-frame`}>
                    {isRelatedURL && (
                        <Link to={`${relatedURL}`} className={`${yah40th.relatedPage} related-page`} title="ライブについて">
                            <Image filename={"aboutTheLive.png"} alt={"ライブについて"} />
                        </Link>
                    )}
                    {isComment && (
                        <a href={`${commentURL}`} className={`${yah40th.contribution} contribution`} title="ライブ感想投稿">
                            <Image filename={"fromAudience.png"} alt={"ライブ感想投稿"} />
                        </a>
                    )}
                    </div>
                </figure>

                {isVideoURL && (
                <div class="webCMThumb">
                    <div class="webCMplay"><span class="text">WebCMを再生</span></div>
                </div>
                )}
            </section>
            
            {isVideoURL && (
            <aside className={`${yah40th.webCM} webCM`} data-embedURL={videoURL} style={{ display: `none` }}>
                <span className={`${yah40th.pause} pause`}>閉じる</span>
            </aside>
            )}

            <section className={`${yah40th.theBand} the-band`}>
                <h2 className={`${yah40th.bandName} band-name aspect narrow`}>{bandName}</h2>
                <ul className={`${yah40th.bandMember} band-member aspect narrow`}>
                {bandMember.map((key, i) => {
                    return(
                        <li className={`${yah40th.member} m${i}`} key={i}>{key}</li>
                    )
                })}
                </ul>
            </section>
         
            {isNotice && (
            <section className={`${yah40th.notice} notice`}>
                <div dangerouslySetInnerHTML={{ __html: notice }} />
            </section>
            )}
            
            <section className={`${yah40th.tourDetail} tour-detail`}>

                <div className={`${yah40th.gigs} gigs`}>
                    {tourInfo.map((key, i) => {
                        let info = key.node.frontmatter
                        let date = common.formatDate(new Date(info.date), 'yyyy年M月d日');
                        let date_iso = common.formatDate(new Date(info.date), 'yyyy-MM-dd');
                        let isOfferURL = (info.url) ? true : false;
                        let isPostponed = (info.eventStatus === 'EventPostponed') ? true : false;
                        return (
                            <div className={`${yah40th.gig} gig ${info.slug}`} key={i} itemscope itemtype="http://schema.org/Event">
                                <h2 className={`${yah40th.venue} venue aspect narrow`} itemprop="location" itemscope itemtype="http://schema.org/Place"><span itemprop="name">{info.venue}</span></h2>
                                <p className={`${yah40th.gigTitle} gig-title`} itemprop="name">{info.title}</p>
                                <ul className={`${yah40th.gigDate} gig-date`}>
                                    <li className={`${yah40th.date} date`}>{date}（{info.week}）</li>
                                    <li className={`${yah40th.doorOpen} door-open`} itemprop="doorOpen" datetime={`${date_iso}T${info.doorOpen}`}>{info.doorOpen}</li>
                                    <li className={`${yah40th.start} start`} itemprop="startDate" datetime={`${date_iso}T${info.start}`}>{info.start}</li>
                                </ul>
                                <meta itemtype="http://schema.org/PerformingGroup" itemprop="name" itemscope content={`${info.performer}`} />
                                <meta itemtype="http://schema.org/Offer" itemprop="price" itemscope content={`${info.price}`} />
                                <meta itemtype="http://schema.org/Offer" itemprop="priceCurrency" itemscope content={`${info.priceCurrency}`} />
                                {isOfferURL && (
                                <meta itemtype="http://schema.org/Offer" itemprop="url" itemscope content={`${info.url}`} />
                                )}
                                <meta itemtype="http://schema.org/Offer" itemprop="validFrom" itemscope content={`${info.validFrom}`} />
                                {isPostponed && (
                                <meta itemtype="http://schema.org/EventPostponed" itemprop="eventStatus" />
                                )}
                                {isPostponed && (
                                <meta itemtype="http://schema.org/Event" itemprop="previousStartDate" itemscope content={`${info.previousStartDate}`} />
                                )}

                                <div className={`${yah40th.info} info`}>
                                    <section class="concert-info aspect narrow">
                                        <div dangerouslySetInnerHTML={{ __html: key.node.html }} />
                                    </section>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <section class="legal-line aspect narrow">
                    <div dangerouslySetInnerHTML={{ __html: pageInfo.html }} />
                </section>
            </section>

            <section className={`${yah40th.relatedProduct} related-product`}>
                <div className={`${yah40th.relatedProductFrame} related-product-frame`}>
                    <CboxInfo />
                </div>
            </section>

        </div>
    </Layout>
    )
}

export default Yah40th