import React from 'react'
import {Link} from 'gatsby'

import Image from "./image"
import cbox from "./css/ssd-cbox.module.css"

const CboxInfo = () => {

  return (
    <div className={`${cbox.cboxInfoFrame} cbox-info-frame`} >
        <figure className={`${cbox.mainVisual} main-visual`}>
        <Link to={`/Save-it-for-a-Sunny-Day/complete-box`} title="佐野元春25タイトル">
            <Image filename={"complete-box-product.png"} alt="佐野元春25タイトル" />
        </Link>
        </figure>
        <div className={`${cbox.productInfo} product-info`}>
            <h1 className={`${cbox.productTitle} product-title aspect narrow`}>MOTOHARU SANO <br />THE COMPLETE ALBUM COLLECTION 1980-2004</h1>
            <p className={`${cbox.releaseDate} release-date`}>2021年6月16日発売</p>
            <div className={`${cbox.productDetail} product-detail`}>
                <ul className={`${cbox.productSpec} product-spec`} id="edition">
                    <li><span className={`${cbox.key} key`}>完全生産限定盤BOXセット</span></li>
                    <li><span className={`${cbox.key} key`}>紙ジャケット仕様 CD×29枚組(25タイトル)</span></li>
                    <li><span className={`${cbox.key} key`}>Ted Jensenによる最新リマスタリング(全オリジナル・アルバム)</span></li>
                    <li><span className={`${cbox.key} key`}>解説と歌詞、フォト満載の豪華400ページブックレット</span></li>
                    <li><span className={`${cbox.key} key`}>「ハートランドからの手紙」特別版ブロシェット</span></li>
                    <li><span className={`${cbox.key} key`}>タロットカード「世界」「星」「太陽」3種</span></li>
                    <li><span className={`${cbox.key} key`}>箱蓋裏にシリアルナンバー入り</span></li>
                    <li><span className={`${cbox.key} key`}>価格：¥35,000 (税抜)</span></li>
                    <li><span className={`${cbox.key} key`}>品番：MHCL-2871～2899</span></li>
                    <li><span className={`${cbox.key} key`}><a href="https://www.110107.com/s/oto/page/sano_collection?ima=1428" target="_blank" rel="noreferrer" >詳細情報は otonano by Sony Music Direct へ</a></span></li>
                </ul>
                <div className={`${cbox.storesFrame} store-frame`}>
                    <span className={`${cbox.label} label`}>オンラインストアで購入</span>
                    <ul className={`${cbox.stores} stores`}>							
                        <li><a href="https://www.sonymusicshop.jp/m/item/itemShw.php?site=S&cd=MHCL000002871&cid=oto-MHCL000002871" target="_blank" rel="noreferrer" title="ソニーミュージックショップ" class="sonymusic" onclick="recordOutboundLink(this, 'OutboundLink-MS-COMPLETE-ALBUM-BOX-buy', 'sonymusic');return false;">ソニーミュージックショップ</a></li>
                        <li><a href="https://amzn.to/38IEpGP" target="_blank" rel="noreferrer" title="アマゾン" class="amazon" onclick="recordOutboundLink(this, 'OutboundLink-MS-COMPLETE-ALBUM-BOX-buy', 'amazon');return false;">アマゾン</a></li>
                        <li><a href="https://tower.jp/item/5143839" target="_blank" rel="noreferrer" title="タワーレコード" class="tower" onclick="recordOutboundLink(this, 'OutboundLink-MS-COMPLETE-ALBUM-BOX-buy', 'tower');return false;">タワーレコード</a></li>
                        <li><a href="https://www.hmv.co.jp/product/detail/11500241" rel="noreferrer" target="_blank" title="HMV" class="hmv" onclick="recordOutboundLink(this, 'OutboundLink-MS-COMPLETE-ALBUM-BOX-buy', 'hmv');return false;">HMV</a></li>
                        <li><a href="https://books.rakuten.co.jp/rb/16589274" target="_blank" rel="noreferrer" title="楽天BOOKS" class="rakuten" onclick="recordOutboundLink(this, 'OutboundLink-MS-COMPLETE-ALBUM-BOX-buy', 'rakuten');return false;">楽天BOOKS</a></li>
                    </ul>
                </div>
            </div> 
        </div>
    </div>
    )
}

export default CboxInfo