import React from 'react'
import {withPrefix,graphql} from 'gatsby'

import Layout from "../../components/layout"
import Image from "../../components/image"
import { createGlobalStyle } from "styled-components"
import { Helmet } from "react-helmet"
import SEO from "../../components/seo"

import featuredImage from "./ogpImage_ssd-single.png"
import single from "../../components/css/ssd-single.module.css"

const GlobalStyle = createGlobalStyle`
  body { background-color: #070e1b !important; font-family:sans-serif; color: #d7dce4;}
  header {background-color: #729001 !important;}
  .icon {background-position: 0 -30px !important;}
  footer {background-color: #212121 !important; color: gray !important;}
  footer a {color: #fff;}
`
export const query = graphql`
query {
    page: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(single.md)/"}}) {
        edges {
            node {
                fileAbsolutePath
                frontmatter {
                    page
                    productNo
                    releaseDate(formatString: "")
                    title
                    heroText
                    iTunesURL
                    AppleMusicURL
                    SpotifyURL
                    videoURL
                    commentURL
                    artworkImg
                    relatedImg {
                        relativePath
                    }
                    relatedURL
                }
                html
            }
        }
    }
}
`

const Single = (props) => {
    const { page } = props.data;

    var pageInfo = page.edges[0].node;

    // 必須項目
    let title = pageInfo.frontmatter.title
    let heroText = pageInfo.frontmatter.heroText
    let artworkImg = pageInfo.frontmatter.artworkImg
    let productNo = pageInfo.frontmatter.productNo
    // let ogpImg = pageInfo.frontmatter.ogpImg

    // 任意項目
    let commentURL = (pageInfo.frontmatter.commentURL) ? pageInfo.frontmatter.commentURL : ""
    let isComment = (pageInfo.frontmatter.commentURL) ? true : false
    let relatedImg = (pageInfo.frontmatter.relatedImg) ? pageInfo.frontmatter.relatedImg.relativePath : ""
    let relatedURL = (pageInfo.frontmatter.relatedURL) ? pageInfo.frontmatter.relatedURL : ""
    let isRelatedImg = (pageInfo.frontmatter.relatedImg) ? true : false

    // 外部リンク
    let AppleMusicURL = (pageInfo.frontmatter.AppleMusicURL) ? pageInfo.frontmatter.AppleMusicURL : ""
    let SpotifyURL = (pageInfo.frontmatter.SpotifyURL) ? pageInfo.frontmatter.SpotifyURL : ""
    let iTunesURL = (pageInfo.frontmatter.iTunesURL) ? pageInfo.frontmatter.iTunesURL : ""
    let videoURL = (pageInfo.frontmatter.videoURL) ? pageInfo.frontmatter.videoURL : ""

    let isAppleMusic = (pageInfo.frontmatter.AppleMusicURL) ? true : false
    let isSpotify = (pageInfo.frontmatter.SpotifyURL) ? true : false
    let isiTunes = (pageInfo.frontmatter.iTunesURL) ? true : false
    let isVideoEmbed = (pageInfo.frontmatter.videoURL) ? true : false

  return (
    <Layout>
      <Helmet>
        <script src="https://code.jquery.com/jquery-3.4.1.min.js" integrity="sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo=" crossOrigin="anonymous" />
        <script src={withPrefix('product-single-script.js')} type="text/javascript" />
      </Helmet>
        <SEO title={title}
            ogpImage={featuredImage} 
            description='佐野元春40周年記念プロジェクト'/>

        <React.Fragment>
            <GlobalStyle />
        </React.Fragment>

        <div id="product-single" className={`${single.contents} ${productNo}`}>

            <section className={`${single.hero} hero`}>

                <aside className={`${single.webCM} webCM`} data-embedURL={videoURL} style={{ display: `none` }}>
                    <span className={`${single.pause} pause`}>閉じる</span>
                </aside>

                <figure className={`${single.artworkFrame} artwork-frame`}>
                    <i className={`${single.doNotCopy} doNotCopy`}></i>
                    <picture className={`${single.artwork} artwork`}>
                        <h1 className={`${single.title} title`}>{title}</h1>
                        <img src={`${artworkImg}`} className={`${single.artworkImage} artwork-image`} alt={`${title}`} />
                    </picture>
                </figure>

                {isVideoEmbed && (
                    <div className={`${single.playButtonFrame} playButton-frame`}>
                        <i className={`${single.playButton} play`}>再生する</i>
                    </div>
                )}

                <div className={`${single.infoFrame} info_frame`}>
                    <div className={`${single.info} info`}>

                        <div className={`${single.copyFrame} copy-frame`}>
                            <h2 className={`${single.copyText} copy-text aspect narrow`} dangerouslySetInnerHTML={{ __html: heroText }} />
                        </div>

                        {isiTunes && (
                            <ul className={`${single.listenAndBuyTier1} listenAndBuy tier1`}>
                            <li className={`${single.iTunes} service iTunes`}><a href={iTunesURL} className={`${single.iTunesURL}`} target="_blank" rel="noreferrer"><img src="https://www.moto.co.jp/images/svg/services/JP_iTunes_Store_Buy_Badge_RGB_011718.svg" alt="iTunesで購入" className={`${single.Badge} badge`} /></a></li>
                            </ul>
                        )}

                        <ul className={`${single.listenAndBuyTier2} listenAndBuy tier2`}>
                        {isAppleMusic && (
                            <li className={`${single.AppleMusic} service AppleMusic`}><a href={AppleMusicURL} className={`${single.AppleMusicURL}`} target="_blank" rel="noreferrer"><img src="https://www.moto.co.jp/images/svg/services/JP_Apple_Music_Badge_RGB.svg" alt="Apple Musicで聴く" className={`${single.Badge} badge`} /></a></li>
                        )}
                        {isSpotify && (
                            <li className={`${single.Spotify} service Spotify`}><a href={SpotifyURL} className={`${single.SpotifyURL}`} target="_blank" rel="noreferrer"><img src="https://www.moto.co.jp/images/svg/services/badge_ListenOnSpotify.png" alt="Spotifyで聴く" className={`${single.Badge} badge`} /></a></li>
                        )}
                        </ul>

                        <div className={`${single.commentFrame} comment-frame`}>
                        {isComment && (
                            <a href={`${commentURL}`} className={`${single.commentButton} comment-button`} title="リスナーからのコメント募集"><span className={`${single.comment} comment`}>リスナーからの感想募集中</span></a>
                        )}
                        </div>

                        <figure className={`${single.relatedFrame} related-frame`}>
                        {isRelatedImg && (
                            <a href={`${relatedURL}`} className={`${single.packageFrame} package-frame`}>
                                <Image filename={relatedImg} alt="" />
                            </a>
                        )}
                        </figure>

                    </div>
                </div>

            </section>

            <section className={`${single.releaseNote} releaseNote`}>
                <div className={`${single.releaseNoteFrame} releaseNote-frame`}>

                    <ul className={`${single.menuFrame} menu-frame`} style={{ display: `none` }}>
                        <li className={`${single.menu1} menu1 selected`}><i>ライナーノーツ</i></li>
                        <li className={`${single.menu2} menu2`}><i>歌詞</i></li>
                    </ul>

                    <article className={`${single.linerNotes} content linerNotes aspect narrow`} dangerouslySetInnerHTML={{ __html: pageInfo.html }} />

                </div>
            </section>

            <section className={`${single.relatedProducts} releaserelated-productNote`}>
                <div className={`${single.relatedProductsFrame} related-product-frame`}>
                    <div className={`${single.selectionFrame} selection-frame`}>
                        <h2 className={`${single.relatedProductsHeading} related-product-heading`}>佐野元春 &amp; ザ・コヨーテバンド<span className={`BRforSP`}></span>シングル・コレクション</h2>

                        <ul className={`${single.selection} selection`}>
                            <li className={`${single.relatedProductItem} item`}><a href="https://www.moto.co.jp/La-Vita-e-Bella/" className={`${single.relatedProductAnchor}`}><img src="https://www.moto.co.jp/DMANP-012/images/lavitaebella_artowork_320.jpg" className={`${single.relatedProductImage} la-vita-e-bella`} alt="ラ・ヴィータ・エ・ベラ" />ラ・ヴィータ・エ・ベラ<span className={`${single.subText} subText`}>2012年発売</span></a></li>
                            <li className={`${single.relatedProductItem} item`}><a href="https://www.moto.co.jp/xmas2013/" className={`${single.relatedProductAnchor}`}><img src="https://www.moto.co.jp/DMANP-012/images/minna_artwork_320.jpg" className={`${single.relatedProductImage} xmas2013`} alt="みんなの願いかなう日まで" />みんなの願いかなう日まで<span className={`${single.subText} subText`}>2013年発売</span></a></li>
                            <li className={`${single.relatedProductItem} item last`}><a href="https://itunes.apple.com/jp/album/rajio-deizu/id828784910?i=828784911" className={`${single.relatedProductAnchor}`}><img src="https://www.moto.co.jp/DMANP-012/images/radiodays_artwork_320.jpg" className={`${single.relatedProductImage} radiodays`} alt="ラジオ・デイズ" />ラジオ・デイズ - Single Ver.<span className={`${single.subText} subText`}>2014年発売</span></a></li>
                            <li className={`${single.relatedProductItem} item`}><a href="https://www.moto.co.jp/DMSNP-009/" className={`${single.relatedProductAnchor}`}><img src="https://www.moto.co.jp/MotoApp/Artworks/w300/DMSNP-009_KimiGaInakucha.jpg" className={`${single.relatedProductImage} kimiga`} alt="君がいなくちゃ" />君がいなくちゃ - Single Ver.<span className={`${single.subText} subText`}>2015年第一弾シングル</span></a></li>
                            <li className={`${single.relatedProductItem} item`}><a href="https://www.moto.co.jp/DMSNP-010/" className={`${single.relatedProductAnchor}`}><img src="https://www.moto.co.jp/DMANP-012/images/border_artwork_320.jpg" className={`${single.relatedProductImage} border`} alt="境界線" />境界線 - Single Ver.<span className={`${single.subText} subText`}>2015年第二弾シングル</span></a></li>
                            <li className={`${single.relatedProductItem} item last`}><a href="https://www.moto.co.jp/DMANP-012/" className={`${single.relatedProductAnchor}`}><img src="https://www.moto.co.jp/MotoApp/Artworks/w300/DMANP-012_aruAkiNoHi.jpg" className={`${single.relatedProductImage} aruAkiNoHi`} alt="或る秋の日" />或る秋の日 - 3 Tracks EP<span className={`${single.subText} subText`}>2016年発売 </span></a></li>
                            <li className={`${single.relatedProductItem} item`}><a href="https://www.moto.co.jp/maniju/sumire/" className={`${single.relatedProductAnchor}`}><img src="https://www.moto.co.jp/MotoApp/Artworks/w300/DMSNP-013_Sumire.jpg" className={`${single.relatedProductImage} sumire`} alt="純恋（すみれ）" />「純恋（すみれ）」- Single<span className={`${single.subText} subText`}>2017年『MANIJU』先行シングル </span></a></li>
                            <li className={`${single.relatedProductItem} item`}><a href="https://www.moto.co.jp/LOVE-Denominator/" className={`${single.relatedProductAnchor}`}><img src="https://www.moto.co.jp/MotoApp/Artworks/w300/DMSNP-016_loveDinominator.jpg" className={`${single.relatedProductImage} LOVE-Denominator`} alt="愛が分母" />「愛が分母」- Single<span className={`${single.subText} subText`}>2019年発売 </span></a></li>
                            <li className={`${single.relatedProductItem} item`}><a href="https://www.moto.co.jp/ItsOnlyEntertainment/" className={`${single.relatedProductAnchor}`}><img src="https://www.moto.co.jp/MotoApp/Artworks/w300/DMSNP-017_Entertainment.jpg" className={`${single.relatedProductImage} ItsOnlyEntertainment`} alt="エンタテイメント！" />「エンタテイメント！」- Single<span className={`${single.subText} subText`}>2020年発売 </span></a></li>
                        </ul>
                    </div>
                </div>
            </section>
            
        </div>
    </Layout>
    )
}

export default Single