import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  body { background-color: #000 !important; font-family:sans-serif;}
  `

const FirstPage = () => (
  <Layout>
    <SEO title='佐野元春40周年記念サイト'
          description='佐野元春40周年記念プロジェクト'/>

      <React.Fragment>
        <GlobalStyle />
      </React.Fragment>
  </Layout>
)

export default FirstPage
