/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

function SEO({
  description,
  lang,
  meta,
  title,
  url,
  ogpImage,
  logo,
  shortName,
  twitter,
  domain,
  author,
  article,
}) {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
            ogpImage
            logo
            shortName
            twitter
            domain
            author
          }
        }
      }
    `
  )

  const seo = {
    title: title || site.siteMetadata.title,
    description: description || site.siteMetadata.description,
    image: ogpImage || site.siteMetadata.ogpImage,
    url: `https://ms40th.moto.co.jp${pathname}`,
  }

  let schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      "@id": url,
      url: url,
      name: `${title} | ${site.siteMetadata.title}`,
      alternateName: site.siteMetadata.shortName,
    },
  ]
  if (article) {
    schemaOrgJSONLD = [
      {
        "@context": "http://schema.org",
        "@type": "BlogPosting",
        "@id": seo.url,
        url: seo.url,
        name: seo.title,
        alternateName: shortName,
        headline: seo.title,
        image: {
          "@type": "ImageObject",
          url: "https://ms40th.moto.co.jp"+seo.image,
        },
        description: seo.description,
        datePublished: "",
        dateModified: "",
        author: {
          "@type": "Person",
          name: author,
        },
        publisher: {
          "@type": "Organization",
          name: site.siteMetadata.author,
          logo: {
            "@type": "ImageObject",
            url: site.siteMetadata.logo,
          },
        },
        isPartOf: site.siteMetadata.url,
        mainEntityOfPage: {
          "@type": "WebSite",
          "@id": site.siteMetadata.url,
        },
      },
    ]
  }



  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={`https://ms40th.moto.co.jp${seo.image}`} />
      <meta itemProp="image" content={`https://ms40th.moto.co.jp${seo.image}`} />
      <meta
        name="apple-mobile-web-app-title"
        content={site.siteMetadata.shortName}
      />
      <meta name="application-name" content={site.siteMetadata.shortName} />
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
      <meta name="format-detection" content="telephone=no" />
      
      {/* OGP */}
      <meta property="og:title" content={seo.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={seo.url} />
      <meta property="og:image" content={`https://ms40th.moto.co.jp${seo.image}`} />
      <meta property="og:site_name" content={site.siteMetadata.shortName} />
      <meta property="fb:admins" content="644333355" />
      <meta property="og:description" content={seo.description} />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={site.siteMetadata.twitter} />
      <meta name="twitter:creator" content={site.siteMetadata.twitter} />
      <meta name="twitter:domain" content={site.siteMetadata.domain} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={`https://ms40th.moto.co.jp${seo.image}`} />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `jp`,
  meta: [],
  description: ``,
  article: false,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  article: PropTypes.bool,
}

export default SEO
