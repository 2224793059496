import React from 'react'
import {Link} from 'gatsby'

import Layout from "../../components/layout"
import Image from "../../components/image"
import { createGlobalStyle } from "styled-components"
import { Helmet } from "react-helmet"
import SEO from "../../components/seo"

import featuredImage from "./ogpImage_ssd-contribution.png"

import ctb from "../../components/css/ssd-contribution.module.css"

const GlobalStyle = createGlobalStyle`
  body { background-color: #f5f5f5 !important; font-family:sans-serif; color: #63605c;}
  .icon {background-position: 0 0 !important;}
  footer {color: #000 !important;}
  footer a {color: #000;}
`

const Contribution = ({ pageContext }) => {

  const siteTitle = "寄稿文募集「40周年に思う、佐野元春のこと」"
  var contri = [];
  const menu = pageContext.menu

  pageContext.datas.map((key, i) => {
    const slug = key.node.frontmatter.slug
    const title = key.node.frontmatter.title
    const writer = key.node.frontmatter.writer
    const types = key.node.frontmatter.type.join('|');

    const list = {"slug":slug, "title":title, "writer":writer, "types":types}
    contri.push(list)
    return contri
  })

  return (
    <Layout>
        <Helmet>
        </Helmet>
        <SEO title={siteTitle}
            ogpImage={featuredImage}
            description='佐野元春40周年記念プロジェクト'/>

        <React.Fragment>
            <GlobalStyle />
        </React.Fragment>

        <div id="contribution" className={``}>

          <section className={`${ctb.hero} hero-frame`}>

              <Link to="/Save-it-for-a-Sunny-Day/" className={`${ctb.heroSticker} hero-sticker`}>
                  <Image filename={"ssd_sticker-circle.png"} alt="Save it for a sunnyday" />
              </Link>

              <h1 className={`${ctb.heroCopy} hero-copy aspect narrow`}>
                佐野元春40周年記念<br />
                ファンからの寄稿文を募集<br />
                テーマは「40周年に思う、佐野元春のこと」
              </h1>
          </section>

          <section className={`${ctb.copyFrame} opy-frame aspect narrow`}>
              <p className={`${ctb.leadCopy} lead-copy`}>
                40周年、それは佐野元春ファンだと堂々と公言できる時。<br />
                佐野元春を巡るあれこれ。<br />
                アーティスト論、音楽批評、元春の音楽とあなたの人生のことなど、<br />
                自薦、他薦問わず、世代を超えた皆さんからの投稿を広く募集します。<br />
                書きおろしだけでなく、<br />
                これまでご自身のブログ等で公開したテキストや投稿なども歓迎します。<br />
              </p>
              <p className={`${ctb.leadCopy} lead-copy`}>
                テーマは、「40周年に思う、佐野元春のこと」。
              </p>
              <p className={`${ctb.leadCopy} lead-copy`}>
                佐野元春を巡るあなたのストーリー<br />
                「サムデイ」との出会い<br />
                佐野元春音楽の個人的レビュー<br />
                あなたが選ぶこの一曲<br />
                元春やその音楽からの影響<br />
                などなど......。<br />
              </p>
              <p className={`${ctb.leadCopy} lead-copy`}>
                私たちの生活や人生の背景に、佐野元春の音楽はどのように響いたか、<br />
                そして私たちにとって、佐野元春の音楽はどんな意味を持ったのか。<br />
                みなさんからの寄稿文を通じて、さまざまな想いを分かち合えればと思います。<br />
              </p>

              <h2 className={`${ctb.articlesTitle} articles-title`}>
              投稿<span className={`${ctb.articlesTitleSmall} articles-titleSmall`}>「40周年に思う、佐野元春のこと」</span>
              </h2>

              <ul id="menu" className={`${ctb.menuFrame} menu-frame aspect narrow`} >
                {menu.map((key, i) => {
                  return(
                    <li className={`${ctb.menu} menu item${i}`} key={i} data-cat={`${key}`}>{key}</li>
                  )
                })}
              </ul>

              <ul className={`${ctb.articleList} lead-article-list`}>
              {contri.map((key, i) => {
                const isTitle = (key.title) ? true : false
                const writer = (key.writer) ? key.writer : '匿名希望'
                return (
                  <li className={`${ctb.articleItem} article-item`} key={i} data-cat={`${key.types}`}>
                    <Link to={`${key.slug}`} className={`${ctb.articleLink} article-link`}>
                    {isTitle && (
                      <span className={`${ctb.articleItemTitle} article-itemTitle`}>「{key.title}」◆ <span className={`${ctb.articleItemWriter} article-itemWriter`}>{writer}</span></span>
                    )}
                    {!isTitle && (
                      <span className={`${ctb.articleItemTitle} article-itemTitle`}>{writer}<span className={`${ctb.articleItemTitleSmall} article-itemTitleSmall`}>さんからの投稿</span></span>
                    )}
                    </Link>
                  </li>
                )
              })}
              </ul>
              <div className={`${ctb.howToContribute} howTo-contribute`}>
              <p className={`${ctb.leadCopy} lead-copy`}>
                字数や締め切り、プロ／アマチュアなどの制限はありません。<br />
                どうぞ気楽にご参加ください。
              </p>
              <p className={`${ctb.leadCopy} lead-copy`}>
                投稿は、こちらのアドレスへメールでお送り下さい。
              </p>
              <p className={`${ctb.leadCopy} lead-copy`}>
                e-mail::<a href="mailto:myvoice@moto.co.jp">myvoice@moto.co.jp</a>
              </p>
              <p className={`${ctb.leadCopy} lead-copy`}>
                みなさんからの投稿をお待ちしています。
              </p>
              </div>
          </section>

        </div>
    </Layout>
    )
}

export default Contribution