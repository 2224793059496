import React from 'react'
import {graphql} from 'gatsby'

import Layout from "../../components/layout"
import Image from "../../components/image"
import { createGlobalStyle } from "styled-components"
import SEO from "../../components/seo"

import featuredImage from "./ogpImage_ssd-tour.png"
import tour from "../../components/css/ssd-tour.module.css"

import * as common from "../../components/common"

const GlobalStyle = createGlobalStyle`
  body { background-color: #1c273c !important; font-family:sans-serif; color: #d7dce4;}
  header {background-color: #729001 !important;}
  .icon {background-position: 0 -30px !important;}
  footer {background-color: #212121 !important; color: gray !important;}
  footer a {color: #fff;}
`
export const query = graphql`
query {
    page: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(tourInfo.md)/"}}) {
        edges {
            node {
            fileAbsolutePath
            frontmatter {
                page
                title
                heroText
                mainImg {
                    relativePath
                }
                videoURL
                mapURL
                commentURL
                ticket
                legalLine
            }
            html
            }
        }
    }

    tourDate: allTourDateYaml(sort: {fields: date, order: ASC}) {
        edges {
            node {
            date
            week
            venue
            url
            start
            slug
            promoter
            doorOpen
            }
        }
    }
}
`

const Tour = (props) => {
    const { page } = props.data;
    const { tourDate } = props.data;

    var pageInfo = page.edges[0].node;

    var tourDateInfo = [];
    tourDate.edges.map(function(node) {
        return tourDateInfo.push(node);
    })

    // 必須項目
    let title = pageInfo.frontmatter.title
    let heroText = pageInfo.frontmatter.heroText
    let mainImg = pageInfo.frontmatter.mainImg.relativePath
    let ticket = pageInfo.frontmatter.ticket

    // 任意項目
    let commentURL = (pageInfo.frontmatter.commentURL) ? pageInfo.frontmatter.commentURL : ""
    let isComment = (pageInfo.frontmatter.commentURL) ? true : false
    let videoURL = (pageInfo.frontmatter.videoURL) ? pageInfo.frontmatter.videoURL : ""
    let isVideoURL = (pageInfo.frontmatter.videoURL) ? true : false
    let mapURL = (pageInfo.frontmatter.mapURL) ? pageInfo.frontmatter.mapURL : ""
    let isMap = (pageInfo.frontmatter.mapURL) ? true : false
    let legalLine = (pageInfo.frontmatter.legalLine) ? pageInfo.frontmatter.legalLine : ""
    let isLegalLine = (pageInfo.frontmatter.legalLine) ? true : false

  return (
    <Layout>
        <SEO title={title}
            ogpImage={featuredImage} 
            description='佐野元春40周年記念プロジェクト'/>

        <React.Fragment>
            <GlobalStyle />
        </React.Fragment>

        <div id="tour" className={`${tour.contents}`}>

            <section className={`${tour.hero} hero`}>

                <figure className={`${tour.heroFrame} hero-frame`}>
                    <i className={`${tour.doNotCopy} doNotCopy`}></i>
                    <picture className={`${tour.heroImage} hero-image`}>
                        <h1 className={`${tour.tourTitle} tour-title aspect narrow`} dangerouslySetInnerHTML={{ __html: heroText }} />
                        <Image filename={mainImg} alt={`${title}`} />
                    </picture>
                </figure>

                {isVideoURL && (
                <div class="webCMThumb">
                    <div class="webCMplay"><span class="text">WebCMを再生</span></div>
                </div>
                )}

            </section>

            <aside className={`${tour.webCM} webCM`} data-embedURL={videoURL} style={{ display: `none` }}>
                <span className={`${tour.pause} pause`}>閉じる</span>
            </aside>

            <section className={`${tour.touDetail} tour-detail`}>
                <div className={`${tour.info} info`}>

                    <div className={`${tour.lead} lead aspect narrow`} dangerouslySetInnerHTML={{ __html: pageInfo.html }} />

                    {isComment && (
                        <a href={`${commentURL}`} className={`${tour.contribution} contribution`}>
                            <Image filename={"fromAudience.jpg"} alt={"ライブ感想投稿"} />
                        </a>
                    )}

                    {isMap && (
                        <aside className={`${tour.map} map`}>
                            <iframe src={`${mapURL}`} width="440" height="660" title="map"></iframe>
                        </aside>
                    )}

                </div>

                <section className={`${tour.tourDate} tour-date`} itemscope itemtype="http://schema.org/Event">
                    <meta itemprop="name" content="" />
                    <meta itemprop="image" content="" />
                    <ul className={`${tour.touDateHeading} tour-date-heading`}>
                        <li className={`${tour.date} date`}>公演日</li>
                        <li className={`${tour.venue} venue`}>会場名</li>
                        <li className={`${tour.doorOpen} door-open`}>開場</li>
                        <li className={`${tour.start} start`}>開演</li>
                    </ul>

                    {tourDateInfo.map((key, i) => {
                        const date = common.formatDate(new Date(key.node.date), 'yyyy年M月d日');
                        return (
                            <ul className={`${tour.gig} gig ${key.node.slug}`} key={i}>
                                <div className={`${tour.noticeFrame} notice-frame`}>
                                    <p className={`${tour.noticeText} notice-text`}></p>
                                </div>
                                <li className={`${tour.date} date`}>{date}（{key.node.week}）</li>
                                <li className={`${tour.venue} venue`}>
                                    <span itemprop="name">{key.node.venue}</span>
                                </li>
                                <li className={`${tour.doorOpen} door-open`}>{key.node.doorOpen}</li>
                                <li className={`${tour.start} start`}>{key.node.start}</li>
                                <li className={`${tour.promoter} promoter`}>
                                <a href={`${key.node.url}`} target="_blank" rel="noreferrer">{key.node.promoter}</a>
                                </li>
                            </ul>
                        )
                    })}

                    <div className={`${tour.ticket} ticket`}>
                        <h2 className={`${tour.ticketHeading} ticket-heading`}>チケット料金</h2>
                        {ticket.map((key, i) => {
                            return(
                                <li className={`${tour.ticketLabel} ticket-label-${i}`} key={i}>{key}</li>
                            )
                        })}
                    </div>

                </section>

                {isLegalLine && (
                    <section className={`${tour.legalLine} legal-line`} dangerouslySetInnerHTML={{ __html: legalLine }} />
                )}
            </section>


        </div>
    </Layout>
    )
}

export default Tour