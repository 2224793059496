import React from 'react'
import {withPrefix,Link,graphql} from 'gatsby'

import Layout from "../../components/layout"
import Image from "../../components/image"
import { createGlobalStyle } from "styled-components"
import { Helmet } from "react-helmet"
import SEO from "../../components/seo"
import featuredImage from "./ogpImage_ssd-merchandise.png"

import * as common from "../../components/common"
import mer from "../../components/css/ssd-merchandise.module.css"

const GlobalStyle = createGlobalStyle`
  body { background-color: #f5f5f5 !important; font-family:sans-serif; color: white;}
  .icon {background-position: 0 0 !important;}
  .ssd-frame {display:none;}
  footer {color: #454545 !important;}
  footer a {color: #000;}
`
export const query = graphql`
query {
  page: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(merchandise.md)/"}}) {
    edges {
      node {
        frontmatter {
          page
          title
          thumbsImg {
            relativePath
          }
          order
        }
        html
        fileAbsolutePath
      }
    }
  }

  product: allMarkdownRemark(filter: {frontmatter: {page: {eq: "merchandise"}}}, sort: {fields: frontmatter___order, order: ASC}) {
    edges {
      node {
        fileAbsolutePath
        frontmatter {
          page
          url
          date(formatString: "")
          title
          thumbsImg {
            relativePath
          }
          order
          productNo
          productType
          price
          releaseDate(formatString: "")
        }
        html
      }
    }
  }
}
`

const Merchandise = (props) => {
  const { page } = props.data;
  const { product } = props.data;

  var pageInfo = page.edges[0].node;

  var ProductInfo = [];
  product.edges.map(function(node) {
    return ProductInfo.push(node)
  })

  const siteTitle = "佐野元春×BEAMS 40TH.オリジナルグッズ／ウェア"

  // generate menu
  var m = [];
  ProductInfo.map((key, i) => {
    const productType = key.node.frontmatter.productType
    if (typeof productType !== 'undefined' && productType !== '') {
      return m.push(productType);
    }
    return m
  });

  const menu = Array.from(new Set(m));

  return (
    <Layout>
      <Helmet>
        <script src="https://code.jquery.com/jquery-3.4.1.min.js" integrity="sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo=" crossOrigin="anonymous" />
        <script src={withPrefix('merchandise-script.js')} type="text/javascript" />
      </Helmet>
      <SEO title={siteTitle}
          ogpImage={featuredImage} 
          description='佐野元春40周年記念プロジェクト'/>

      <React.Fragment>
        <GlobalStyle />
      </React.Fragment>

      <div id="merchandise" className={``}>

        <section className={`${mer.hero} hero-frame`}>
          <Link to="/Save-it-for-a-Sunny-Day/" className={`${mer.heroSticker} hero-sticker`}>
            <Image filename={"ssd_sticker-circle.png"} alt="Save it for a sunnyday" />
          </Link>
          <div className={`${mer.heroTitleFrame} hero-title-frame`} >

            <figure className={`${mer.heroTitle} hero-title`}>
              <Image filename={"Moto-BEAMS-title.png"} alt={pageInfo.frontmatter.title}  />
            </figure>

            <div className={`${mer.heroCopyFrame} hero-copy-frame`} >
              <article className={`${mer.heroCopy} hero-copy aspect narrow`} dangerouslySetInnerHTML={{ __html: pageInfo.html }} />
            </div>

            <ul className={`${mer.menuFrame} menu-frame aspect narrow`} >
              <li className={`${mer.menu} menu All`} data-menu="All">All</li>
              {menu.map((key, i) => {
                return(
                  <li className={`${mer.menu} menu ${key}`} key={i} data-menu={`${key}`}>{key}</li>
                )
              })}
            </ul>

          </div>

        </section>

        <section className={`${mer.productFrame} product-frame`}>
        {ProductInfo.map((key, i) => {
          const productNo = key.node.frontmatter.productNo
          const productType = key.node.frontmatter.productType
          const title = key.node.frontmatter.title
          const price = key.node.frontmatter.price
          const thumbsImg = (key.node.frontmatter.thumbsImg) ? key.node.frontmatter.thumbsImg.relativePath : "ffes_badge-comingSoon-720x540.png"
          const url = (key.node.frontmatter.url) ? key.node.frontmatter.url : ""
          const isURL = (url !== "") ? true : false
          var releaseDate = key.node.frontmatter.releaseDate.slice(0,10)
          releaseDate = common.formatDate(new Date(releaseDate), 'yyyy年M月dd日');
          const description = key.node.html

          return (
            <div className={`${mer.itemFrame} itemFrame p-${productNo}`} key={i} data-menu={`${productType}`}>

              <div className={`${mer.itemImageFrame} item-image-frame`}>
                {isURL && (
                  <a href={url} className={`${mer.itemImageLink} item-image-link`} target="_blank" rel="noreferrer">
                    <Image filename={thumbsImg} alt={title} className={`${mer.itemImage} item-image`} />
                  </a>
                )}
                {!isURL && (
                  <Image filename={thumbsImg} alt={title} className={`${mer.itemImage} item-image`} />
                )}
                <div className={`${mer.itemTitleFrame} item-title-frame`}>
                  <h2 className={`${mer.title} title`} dangerouslySetInnerHTML={{ __html: title }} />
                  <span className={`${mer.price} price`}>￥{price}（税込）</span>
                </div>
              </div>

              <div className={`${mer.infoFrame} copyFrame`}>
                <div className={`${mer.description} description aspect narrow`} dangerouslySetInnerHTML={{ __html: description }} />
                {isURL && (
                  <a href={url} className={`${mer.button} button`} target="_blank" rel="noreferrer">
                    <span className={`${mer.buttonLabel} button-label`}>BEAMS ONLINE SHOP 販売ページへ</span>
                  </a>
                )}
                {!isURL && (
                  <figure className={`${mer.button} button soon`}>
                    <span className={`${mer.buttonLabel} button-label`}>{releaseDate} 販売スタート</span>
                  </figure>
                )}
              </div>

            </div>
          )
        })}
        </section>
        <p className={`${mer.commingSoon} comming-soon`}>12月に新たな商品が追加されます。<br />お楽しみに！</p>
      </div>
    </Layout>
  )
}

export default Merchandise