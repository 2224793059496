import React from "react"
import {Link} from 'gatsby'
import Layout from "../../components/layout"
import Image from "../../components/image"
import { createGlobalStyle } from "styled-components"
import { Helmet } from "react-helmet"
import SEO from "../../components/seo"

import featuredImage from "./ogpImage_ssd-contribution.png"
import * as common from "../../components/common"
import ctb from "../../components/css/ssd-contribution.module.css"

const GlobalStyle = createGlobalStyle`
  body { background-color: #f5f5f5 !important; font-family:sans-serif; color: #63605c;}
  .icon {background-position: 0 0 !important;}
  footer {color: #000 !important;}
  footer a {color: #000;}
`

export default ({ pageContext }) => {
    const siteTitle = "寄稿文「40周年に思う、佐野元春のこと」"

    const data = pageContext.data;
    const title = data.frontmatter.title
    const date = data.frontmatter.date
    const writer = data.frontmatter.writer
    const slug = data.frontmatter.slug

    var types = ['ALL']
    data.frontmatter.type.map((type, i) => {
        types.push(type)
        return types
    })

    const catList = pageContext.catList

    const isTitle = (title) ? true : false
    const isWriter = (writer) ? true : false

    return (
        <Layout>
            <Helmet>
            </Helmet>
            <SEO title={siteTitle}
                ogpImage={featuredImage}
                description='佐野元春40周年記念プロジェクト'/>

            <React.Fragment>
                <GlobalStyle />
            </React.Fragment>

            <div id="contribution" className={`article`}>
            <section className={`${ctb.hero} hero-frame`}>
                <Link to="/Save-it-for-a-Sunny-Day/" className={`${ctb.heroSticker} hero-sticker`}>
                    <Image filename={"ssd_sticker-circle.png"} alt="Save it for a sunnyday" />
                </Link>

                <h1 className={`${ctb.heroCopy} hero-copy aspect narrow`}>
                ファンからの寄稿文<br />
                「40周年に思う、佐野元春のこと」
                </h1>
            </section>

                <section className={`${ctb.articleFrame} article-frame`}>
                    <div className={`${ctb.articleTitleFrame} article-titleFrame`}>
                    {isTitle && (
                        <h1 className={`${ctb.articleTitle} article-title`}>{title}</h1>
                    )}
                    {!isTitle && (
                        <h1 className={`${ctb.articleTitle} article-title`}>無題</h1>
                    )}
                    {isWriter && (
                        <span className={`${ctb.articleWriter} article-writer`}>{writer}</span>
                    )}
                    {!isWriter && (
                        <span className={`${ctb.articleWriter} article-writer`}>匿名希望</span>
                    )}
                        <time className={`${ctb.articleDate} article-date`}>{common.formatDate(new Date(date), 'yyyy年M月d日')}</time>
                    </div>
                    <article className={`${ctb.articleEntry} article-entry`}>
                        <div dangerouslySetInnerHTML={{ __html: data.html }} />
                    </article>
                </section>

                <div className={`${ctb.articlePageNavFrame}`}>
                {types.map((type, i) => { // 記事に指定されたカテゴリーを全て検証
                    var prev,next
                    var isPrev = false, isNext = false
                    var idx = catList.findIndex((list) => { 
                        return (list.type === type) // 一覧から該当カテゴリーを抽出
                    });
                    if (idx !== -1) {
                        var num = catList[idx].slug.findIndex((list) => {
                            return (list === slug) // カテゴリー番号を取得
                        });
                        // 前後の記事番号を取得
                        if (num > 0) {
                            isPrev = true
                            prev = catList[idx].slug[num - 1]
                        }
                        if (num < catList[idx].slug.length - 1) {
                            isNext = true
                            next = catList[idx].slug[num + 1]
                        }
                    }
                    return (
                        <nav className={`${ctb.articlePageNav} article-pageNav`} data-cat={`${type}`} key={i}>
                        {isPrev && (
                            <Link to={`../${prev}?cat=${type}`} className={`${ctb.articlePrev} article-prev`}>前の投稿へ</Link>
                        )}
                        <div className={`${ctb.articleTopFrame} article-topFrame`}>
                            <Link to={`../?cat=${type}`} className={`${ctb.articleTop} article-top`}>投稿一覧に戻る</Link>
                        </div>
                        {isNext && (
                            <Link to={`../${next}?cat=${type}`} className={`${ctb.articleNext} article-next`}>次の投稿へ</Link>
                        )}
                        </nav>
                    )
                })}
                </div>

            </div>
        </Layout>
    )
}
