import React from 'react'
import {Link,graphql} from 'gatsby'

import Layout from "../../components/layout"
import Image from "../../components/image"
import { createGlobalStyle } from "styled-components"
import { Helmet } from "react-helmet"
import SEO from "../../components/seo"

import separator from "./separator.png"
import featuredImage from "./ogpImage_ssd-pia.png"
import pia from "../../components/css/ssd-mrs.module.css"

import * as common from "../../components/common"

const GlobalStyle = createGlobalStyle`
  body { background-color: #070e1b !important; font-family:sans-serif; color: #d7dce4;}
  .ssd-frame {display:none;}
  .icon {background-position: 0 -30px !important;}
  footer {color: #fff !important;}
  footer a {color: #fff;}
`
export const query = graphql`
query {
    item: allPiaInterviewYaml(sort: {fields: date, order: ASC}) {
        edges {
            node {
            date
            title
            url
            vol
            }
        }
    }
}
`

const PIA = (props) => {
    const { item } = props.data;

    var articleInfo = [];
    item.edges.map(function(node) {
        return articleInfo.push(node);
    })

  const siteTitle = "ぴあインタビュー：佐野元春を成立させるクリエイティブのかけら"

  return (
    <Layout>
        <Helmet></Helmet>
        <SEO title={siteTitle}
            ogpImage={featuredImage} 
            description='佐野元春40周年記念プロジェクト'/>

        <React.Fragment>
            <GlobalStyle />
        </React.Fragment>

        <div id="pia-inteview" className={``}>

        <section className={`${pia.hero} hero-frame`}>
            <figure className={`${pia.heroImageFrame} hero-image-frame`}>
                <Image filename={"pia-interview-title.png"} className={`${pia.heroImage} hero-image`} alt="佐野元春を成立させるクリエイティブのかけら" />
            </figure>
            <Link to="/Save-it-for-a-Sunny-Day/" className={`${pia.heroSticker} hero-sticker`}>
                <Image filename={"ssd_sticker-circle.png"} alt="Save it for a sunnyday" />
            </Link>
        </section>

        <section className={`${pia.leadCopyFrame} lead-copy-frame aspect narrow`}>
            <p className={`${pia.leadCopy} lead-copy`}>
            1980年のレコードデビューから40年。時代を象徴する数々の名曲を世に送り出してきた佐野元春。楽曲・アルバムが生まれた時代背景、多様なメディアと切り結びながら独特の世界観を構築した当時の心情、佐野が疾走しつづけてきた社会・時代を掘り下げながら、今なお幅広い層に影響を与える佐野元春のクリエイティブの源泉をひもといていく。
            </p>
            <span className={`${pia.piaSubCopy} pia-sub-copy`}>2020年9月12日から、毎週土曜更新、深夜0:00公開</span>
        </section>

        <hr className={`${pia.separator} separator`} style={{ backgroundImage: `url(${separator})`}} />

        <section className={`${pia.articles} pia-articles`}>
        {articleInfo.map((key, i) => {
            const artcileDate = common.formatDate(new Date(key.node.date), 'M月d日');
            return (
                <a href={`${key.node.url}`} target="_blank" rel="noreferrer" className={`${pia.articlesFrame} pia-articles-frame`} key={i}>
                    <span className={`${pia.articleInfo} pia-article-info`}>第{key.node.vol}章　{artcileDate}更新</span>
                    <span className={`${pia.articleTitle} pia-article-title`}>{key.node.title}</span>
                </a>
            )
        })}
        </section>

        </div>
    </Layout>
    )
}

export default PIA